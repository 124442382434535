import React, { useMemo } from 'react';

const TagCloud = ({ trades, selectedTag, onTagClick }) => {
  const sortedTags = useMemo(() => {
    const tagCounts = {};
    trades.forEach(trade => {
      if (trade.tags) {
        trade.tags.forEach(tag => {
          tagCounts[tag] = (tagCounts[tag] || 0) + 1;
        });
      }
    });
    
    return Object.keys(tagCounts).sort((a, b) => tagCounts[b] - tagCounts[a]);
  }, [trades]);

  return (
    <div className="tag-cloud-container">
      <div className="tag-cloud">
        {sortedTags.map((tag, index) => (
          <button 
            key={index} 
            className={`tag-cloud-item ${selectedTag === tag ? 'selected' : ''}`}
            onClick={() => onTagClick(tag)}
          >
            {tag}
          </button>
        ))}
      </div>
    </div>
  );
};

export default TagCloud;