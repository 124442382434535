import React from 'react';
import TradeItem from './TradeItem';

const TradesList = ({ trades, showCategories }) => {
  if (showCategories) {
    // Group trades by category
    const tradesByCategory = trades.reduce((acc, trade) => {
      if (!acc[trade.category]) {
        acc[trade.category] = [];
      }
      acc[trade.category].push(trade);
      return acc;
    }, {});

    return (
      <div className="trades-list">
        {Object.entries(tradesByCategory).map(([category, categoryTrades]) => (
          <div key={category} className="category-section">
            <h2>{category}</h2>
            {categoryTrades.map(trade => (
              <TradeItem key={trade.id} trade={trade} />
            ))}
          </div>
        ))}
      </div>
    );
  } else {
    // Show filtered list without categories
    return (
      <div className="trades-list filtered">
        {trades.map(trade => (
          <TradeItem key={trade.id} trade={trade} />
        ))}
      </div>
    );
  }
};

export default TradesList;