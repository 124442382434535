import React from 'react';

const TradeItem = ({ trade }) => {
  const { name, company, phone, email, tags, category } = trade;

  // Function to generate email subject and body based on category
  const getEmailDetails = (category) => {
    let subject, body;

    switch (category) {
      case "Heat & Plumbing":
        subject = "Enquiry: Plumbing/ Heating Service Request";
        body = "Hi, you were recommended by my neighbours in Wingrave -- could you let me know a ballpark quote and timings for the job below?";
        break;
      case "Electrics":
        subject = "Enquiry: Electrical Service Request";
        body = "HHi, you were recommended by my neighbours in Wingrave -- could you let me know a ballpark quote and timings for the job below?";
        break;
      case "DIY & Decoration":
        subject = "Enquiry: DIY/ Decoration project";
        body = "Hi, you were recommended by my neighbours in Wingrave -- could you let me know a ballpark quote and timings for the job below?";
        break;
      case "Roof & Outside":
        subject = "Enquiry: Exterior Work Request";
        body = "Hi, you were recommended by my neighbours in Wingrave -- could you let me know a ballpark quote and timings for the job below?";
        break;
      default:
        subject = "Enquiry: Service Inquiry";
        body = "Hi, you were recommended by my neighbours in Wingrave -- could you let me know a ballpark quote and timings for the job below?";
    }

    return { subject: encodeURIComponent(subject), body: encodeURIComponent(body) };
  };

  const { subject, body } = getEmailDetails(category);

  return (
    <div className="trade-item">
      <div className="trade-details">
        <div className="trade-header">
          <span className="trade-name">{name}</span>
          {company && <span className="trade-company">{company}</span>}
        </div>
        <div className="trade-tags">
          {tags && tags.map((tag, index) => (
            <span key={index} className="tag">{tag}</span>
          ))}
        </div>
        <div className="trade-contact">
          {phone && (
            <a href={`tel:${phone}`} className="contact-item">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="contact-icon"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
              <span>{phone}</span>
            </a>
          )}
          {email && (
            <a href={`mailto:${email}?subject=${subject}&body=${body}`} className="contact-item">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="contact-icon"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
              <span>{email}</span>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default TradeItem;
